






























































































































import MNotificationVue from "@/mixins/MNotification.vue";
import { ResponseAvailableDeliveryOrderAR, ResponseDeliveryOrder, ResponseListAvailableDeliveryOrderAR } from "@/models/interface-v2/delivery-order.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { deliveryOrderService } from "@/services-v2/delivery-order.service";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
export default Vue.extend({
  name: "InvoiceFindDO",
  components: {},
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      dtSource: [] as ResponseAvailableDeliveryOrderAR[],
      selectedDO: [] as ResponseDeliveryOrder[],
      duplicate: [false, ""],
      dtListDeliveryOrder: {} as ResponseListAvailableDeliveryOrderAR,
      queryParams: {
        page: 0,
        limit: 20,
        sorts: "deliveryOrderDate:desc",
        search: "status~UNBILLED_OR_status~PARTIAL_INVOICING",
      } as RequestQueryParamsModel,
    };
  },
  computed: {
    ...mapState({
      form: (st: any) => st.invoiceStore.form,
      formCurrencyCode: (st: any) => st.invoiceStore.formCurrencyCode,
      tabDetailSource: (st: any) => st.invoiceStore.tabDetailSource,
    })
  },
  watch: {},
  created() {
    this.queryParams.search = `status~UNBILLED_OR_status~PARTIAL_INVOICING_AND_currency.currencyCode~${this.formCurrencyCode || "IDR"}_AND_customer.secureId~${this.form.customerId || ""}`;
    this.getListDO(this.queryParams);
    this.selectedDO = this.form.deliveryOrders;
  },
  methods: {
    ...mapMutations({
      setForm: "invoiceStore/SET_FORM",
      setTabDetailSource: "invoiceStore/SET_TAB_DETAIL_SOURCE",
    }),
    handleClose(): void {
      this.$emit("input", false);
    },
    pickLine(r: ResponseDeliveryOrder): void {
      const dt = this.selectedDO.find(x => x.id === r.id);
      if (dt) {
        this.duplicate = [true, dt.deliveryOrderNumber];
      } else {
        this.duplicate = [false, ""];
        this.selectedDO.push(r);
        // sort by newest date
        this.selectedDO.sort((a, b) => (new Date(b.deliveryOrderDate).valueOf() - new Date(a.deliveryOrderDate).valueOf()));
        this.commitStore();
      }
    },
    removeDO(item: ResponseDeliveryOrder): void {
      const { selectedDO } = this;
      this.selectedDO = selectedDO.filter(x => x.id !== item.id);
      this.removeRelatedDO();
      this.commitStore();
    },
    removeRelatedDO(): void {
      const { selectedDO } = this;
      const colDOs: string[] = selectedDO.map(x => x.deliveryOrderNumber);
      const source = this.tabDetailSource.filter(x => colDOs.includes(x.documentReference));
      this.setTabDetailSource(source);
    },
    commitStore(): void {
      this.setForm({
        ...this.form,
        deliveryOrders: this.getSelectedDO(),
        deliveryOrderIds: this.getSelectedDO().map(x => x.id),
      });
    },
    getSelectedDO() {
      return this.selectedDO.map((x) => ({
        ...x,
        generated: x.generated || false
      }));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.queryParams.limit = response.size;
      this.queryParams.page = 0;
      this.getListDO(this.queryParams);
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.queryParams.page = response.page - 1;
      this.getListDO(this.queryParams);
    },
    async getListDO(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await deliveryOrderService.getListAvailableAR(params);
        this.dtListDeliveryOrder = res;
        this.dtSource = res.data;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    refresh(): void {
      this.getListDO(this.queryParams);
    },
  },
});
