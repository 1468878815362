var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_find_delivery_order"),
        visible: _vm.value,
        "destroy-on-close": true,
        closable: false,
        width: "820px"
      }
    },
    [
      _vm.duplicate[0]
        ? _c("a-alert", {
            staticClass: "mb-3",
            attrs: {
              banner: "",
              message: _vm.$t("notif_duplicate_item", {
                data: _vm.duplicate[1]
              }),
              type: "error"
            }
          })
        : _vm._e(),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex" } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "reload", loading: _vm.loading },
                  on: { click: _vm.refresh }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_refresh")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-table",
                {
                  attrs: {
                    "data-source": _vm.dtSource,
                    scroll: { y: 320 },
                    loading: _vm.loading,
                    "row-key": function(r) {
                      return r.id
                    },
                    pagination: false
                  }
                },
                [
                  _c(
                    "a-table-column",
                    {
                      key: "deliveryOrderNumber",
                      attrs: { "data-index": "deliveryOrderNumber" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(record.deliveryOrderNumber || "-") +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_delivery_order_number")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "status",
                      attrs: { "data-index": "status" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _vm._v(" " + _vm._s(record.status || "-") + " ")
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_status")))
                      ])
                    ]
                  ),
                  _c(
                    "a-table-column",
                    {
                      key: "action",
                      attrs: { align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(text, record) {
                            return [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.pickLine(record)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_choose")) + " "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("lbl_action")))
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 16 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.dtListDeliveryOrder.totalElements,
                  "page-size-set": _vm.queryParams.limit,
                  "id-pagination": "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { sm: 24, md: 8, align: "end" } },
            [
              _c("a-tag", { attrs: { color: "#8c8c8c" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("lbl_total_found")) +
                    " : " +
                    _vm._s(_vm.dtListDeliveryOrder.totalElements) +
                    " "
                )
              ])
            ],
            1
          ),
          _c("a-col", { attrs: { span: 24 } }, [
            _c("p", { staticClass: "text-subtitle-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("lbl_selected_delivery_order")) + " ")
            ]),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.selectedDO.length,
                    expression: "!selectedDO.length"
                  }
                ]
              },
              [_vm._v(_vm._s(_vm.$t("lbl_no_data")))]
            ),
            _c(
              "div",
              _vm._l(_vm.selectedDO, function(item) {
                return _c(
                  "a-button",
                  {
                    key: item.id,
                    staticClass: "mr-2 mb-2",
                    attrs: { icon: "delete", type: "dashed" },
                    on: {
                      click: function($event) {
                        return _vm.removeDO(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.deliveryOrderNumber || "-") + " ")]
                )
              }),
              1
            )
          ])
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { on: { click: _vm.handleClose } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "back",
              attrs: { type: "primary" },
              on: { click: _vm.handleClose }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_ok")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }